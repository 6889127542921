<template>
    <div id="about_us" class="p-0 container-fluid">
        <div class="row m-0 bg-blue">
            <div class="col-md-6 col-12 about-us-img p-0" style="min-height: 550px;"></div>
            <div class="col-md-6 col-12 about-us-desc text-white" style="min-height: 550px; display:flex; align-items: center; justify-content: center;">
                <div>
                    <h2 style="font-size: 45px;">Who We Are?</h2>
                    <h3 class="pt-5" style="font-size: 25px; font-weight: normal;">Know more about {{getCompanyInfo.name}}</h3>
                    <div class="about-us-text" v-html="getCompanyInfo.website_content.aboutus_text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'AboutUs',
        computed:{
            ...mapGetters(['getCompanyInfo']),
        }
    }
</script>

<style scoped>
    .about-us-text{
        line-height: 2.0;
        font-size: 16px;
    }
    .about-us-img{
        background:url("https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/images/bus_indore.webp") right center no-repeat;
    }
    .about-us-desc{
        padding: 15px;
    }
    @media screen and (max-width: 768px) {
        .about-us-desc{
            padding: 30px;
        }
    }
</style>
